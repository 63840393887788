import { yupResolver } from "@hookform/resolvers/yup";
import { Icon } from "@progress/kendo-react-common";
import { useCallback, useMemo, useState } from "react";
import { type FieldPath, useForm } from "react-hook-form";
import {
	type InferType,
	array,
	bool,
	date,
	mixed,
	number,
	object,
	string,
} from "yup";
import { Flex } from "./GenericFlex";
import {
	GenericFormButtons,
	InputCheckbox,
	InputDateTime,
	InputDropzone,
	InputMultiSelect,
	InputNumber,
	InputSelect,
	InputText,
	InputTextArea,
	type LoadOptionsFn,
} from "./GenericForm";
import { noInvalidDate, noNaN } from "./helpers";

const JobSchema = object({
	id: number().transform(noNaN).label("ID"),
	jobTypeId: number().label("Job Type").required(),
	customerId: number().label("Customer").required(),
	ispurchaseOrderNumberMandatory: bool(),
	purchaseOrderNumber: string()
		.label("Purchase Order Number")
		.when("ispurchaseOrderNumberMandatory", {
			is: true,
			// biome-ignore lint/suspicious/noThenProperty: it is Yup's API
			then: (schema) => schema.required(),
			otherwise: (schema) => schema.notRequired(),
		}),
	startLocationId: number().label("Start Location").required(),
	startDate: date().transform(noInvalidDate).label("Start Date").required(),
	endLocationId: number().label("End Location").required(),
	endDate: date().transform(noInvalidDate).label("End Date"),
	price: number().min(0).transform(noNaN).label("Price"),
	assignedTo: string().label("Assigned To").required(),
	notes: string().label("Notes"),
	goodsInfo: object({
		constraints: array()
			.of(number().required().label("Constraint"))
			.label("Constraints"),
		name: string().label("Goods Description").required(),
		quantity: number()
			.transform(noNaN)
			.label("Quantity")
			.required()
			.moreThan(0),
		weight: number().transform(noNaN).label("Weight").required().moreThan(0),
	}),
	documents: array().of(mixed<File>().required()).label("Documents"), // not part of the post
	associatedDocuments: array().of(mixed<File>().required()), // not part of the post
	containerNumber: string().label("Container Number"),
	containerPin: string().label("Container Pin"),
	containerSealNumber: string().label("Container Seal Number"),
	useVat: bool().label("Use VAT").default(true).required(),
});

type Job = InferType<typeof JobSchema>;
type JobFormProps = {
	defaultValues?: Partial<Job>;
	lookupJobTypes: LoadOptionsFn;
	lookupCustomers: LoadOptionsFn;
	lookupUsers: LoadOptionsFn;
	lookupLocations: LoadOptionsFn;
	lookupConstraints: LoadOptionsFn;
	onSubmit: (data: Job) => Promise<unknown>;
	onAddLocation?: (callback: (value: number) => void) => void;
};

const useAddLocation = (
	path: FieldPath<Job>,
	setValue: (path: FieldPath<Job>, value: number) => void,
	onAddLocation?: (callback: (value: number) => void) => void,
) => {
	const handleClick = useCallback(() => {
		onAddLocation?.((value) => {
			setValue(path, value);
		});
	}, [onAddLocation, setValue, path]);
	return useMemo(
		() => (
			<>
				{onAddLocation && (
					<div
						style={{ cursor: "pointer" }}
						onClick={handleClick}
						onKeyUp={handleClick}
					>
						<Icon name="plus" />
						<span style={{ fontSize: "0.7rem" }}>New</span>
					</div>
				)}
			</>
		),
		[onAddLocation, handleClick],
	);
};

export const JobForm = ({
	defaultValues,
	lookupJobTypes,
	lookupCustomers,
	lookupUsers,
	lookupLocations,
	lookupConstraints,
	onSubmit,
	onAddLocation,
}: JobFormProps) => {
	const { handleSubmit, reset, control, setValue } = useForm<Job>({
		resolver: yupResolver(JobSchema),
		defaultValues,
	});

	const startLocationButton = useAddLocation(
		"startLocationId",
		setValue,
		onAddLocation,
	);

	const endLocationButton = useAddLocation(
		"endLocationId",
		setValue,
		onAddLocation,
	);

	const [isSubmitting, setIsSubmitting] = useState(false);

	return (
		<form
			className="k-form"
			onSubmit={handleSubmit(async (data) => {
				setIsSubmitting(true);
				await onSubmit?.(data).finally(() => setIsSubmitting(false));
				setIsSubmitting(false);
			})}
		>
			<Flex>
				<div>
					<InputSelect
						control={control}
						schema={JobSchema}
						name="jobTypeId"
						loadOptions={lookupJobTypes}
					/>
					<InputSelect
						control={control}
						schema={JobSchema}
						name="startLocationId"
						loadOptions={lookupLocations}
						label={
							<>
								Start Location
								{startLocationButton}
							</>
						}
					/>
					<InputDateTime
						control={control}
						schema={JobSchema}
						name="startDate"
					/>
					<InputText
						control={control}
						schema={JobSchema}
						name="purchaseOrderNumber"
					/>
					<InputSelect
						control={control}
						schema={JobSchema}
						name="assignedTo"
						loadOptions={lookupUsers}
					/>
					<InputDropzone
						control={control}
						schema={JobSchema}
						name="documents"
					/>
					<InputCheckbox control={control} schema={JobSchema} name="useVat" />
				</div>
				<div>
					<InputSelect
						control={control}
						schema={JobSchema}
						name="customerId"
						loadOptions={lookupCustomers}
					/>
					<InputSelect
						control={control}
						schema={JobSchema}
						name="endLocationId"
						loadOptions={lookupLocations}
						label={
							<>
								End Location
								{endLocationButton}
							</>
						}
					/>
					<InputDateTime control={control} schema={JobSchema} name="endDate" />
					<InputNumber
						control={control}
						schema={JobSchema}
						name="price"
						step={0.01}
					/>
					<InputTextArea control={control} schema={JobSchema} name="notes" />
				</div>
				{defaultValues?.id == null && (
					<div>
						<InputTextArea
							control={control}
							schema={JobSchema}
							name="goodsInfo.name"
						/>
						<InputNumber
							control={control}
							schema={JobSchema}
							name="goodsInfo.quantity"
						/>
						<InputNumber
							control={control}
							schema={JobSchema}
							name="goodsInfo.weight"
						/>
						<InputMultiSelect
							control={control}
							schema={JobSchema}
							name="goodsInfo.constraints"
							loadOptions={lookupConstraints}
						/>
					</div>
				)}
			</Flex>
			<GenericFormButtons
				onReset={() => reset(defaultValues)}
				isSubmitting={isSubmitting}
			/>
		</form>
	);
};
